
import {
    defineComponent, watch, ref, Ref,
    PropType
} from 'vue';
import { isCNServer, isRuServer } from '@/util/location';
import { format } from '@/methods/date';
import { CaptureSearchData } from './all.type';

export default defineComponent({
    props: {
        searchData: {
            type: Object as PropType<CaptureSearchData>,
            required: true
        }
    },
    setup(props, { emit }) {
        // 初始化搜索栏数据
        const formData: Ref<CaptureSearchData> = ref({
            startTime: '',
            endTime: ''
        });
        watch(() => props.searchData, () => {
            formData.value = { ...props.searchData };
        }, {
            immediate: true
        });

        // 返回搜索条件
        const updateToList = () => {
            const param = { ...formData.value };
            param.startTime = format(param.startTime, 'YYYY-MM-DD HH:mm:ss');
            param.endTime = format(param.endTime, 'YYYY-MM-DD HH:mm:ss');
            emit('updateToList', param);
        };

        return {
            isCNServer,
            isRuServer,
            formData,
            updateToList,
            emit
        };
    }
});
